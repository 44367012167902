import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentDetails from "./PaymentDetails";
import { imagePath } from "../DataServices/Image";
import { useSelector,useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import APIList from "../GlobalVariable/APIList";
import { PAGE_NAME } from "../Rootpath/rootPath";
import ApiRequests from "../ApiCall/ApiCall";
import { ActionTypes } from "../constants/actionTypes";

const CardCancel = () => {
  const { id } = useParams();
  const [loading, setLoader] = useState(true);
  const {customer} = useSelector((state)=>state);
    const dispatch = useDispatch();
    const updateState = (actionType, value) => () => {
      dispatch({ type: actionType, payload: value });
      return Promise.resolve();
    };
  useEffect(() => {

  }, []);

  useEffect(() => {
    cardCancel();
  }, []);
  const cardCancel = async () => {
    var data = {
      reference_id: id,
    };
    const response = await ApiRequests.postValues(
      APIList.API_URL,
      APIList.API_LIST.CHECK_TOKEN,
      data
    );
    console.log(response);
    if (response.data) {
      setLoader(false);
      dispatch(
        updateState(ActionTypes.CUSTOMER_DETAILS, {
          customerList: response?.data?.payment_data,
        })
      );
      const customerDetails = response?.data?.payment_data;
      if (customerDetails?.payment_gateway === "ngenius") {
        document.documentElement.style.setProperty(
          "--main-theme-color",
          customerDetails?.theme_color
        );
        document.documentElement.style.setProperty(
          "--main-primary-color",
          customerDetails?.primary_color
        );
      }
    }
  };

  const retry = () =>{
    window.location.href=`${APIList.API_URL,APIList.USER_URL +'/pay/'+ customer?.customerList?.reference_no}`
  }


  return (
    <div className="fls_payment_body">
      <div className="fls_payment_center">
        <PaymentDetails />
        <div className="fls-card-right">
          <div className="fls-customers-details">
            <div className="fls-customer-name">Payment Details</div>
            <div className="fls-customer-contact">Amex, Visa & Mastercard</div>
          </div>
          <div className="fls-card-cancel">
            <div className="fls-cancel">Payment Cancelled</div>
            <img className="fls-cancel-img" src={imagePath("./cancel.jpg")} />
            <div className="fls-cancel-notes">
              Note : Your card not supported
            </div>
            <button className="fls-retry" onClick={retry}>Retry</button>
          </div>
          <div className="fls-card-img">
            <img src={imagePath("./amex.png")} />
            <img src={imagePath("./master.png")} />
            <img src={imagePath("./visa.svg")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCancel;
