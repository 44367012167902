import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ApiRequests from "../ApiCall/ApiCall";
import APIList from "../GlobalVariable/APIList";
import { ActionTypes } from "../constants/actionTypes";
import PaymentDetails from "./PaymentDetails";
import { imagePath } from "../DataServices/Image";
import Loader from "../Loader/loader";

const Directsuccess = () => {
  const { customer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const { id } = useParams();
  const [loading, setLoader] = useState(true);
  const [transSuccess, setTransaSuccess] = useState(null);

  useEffect(() => {
    Success();
    transaction();
  }, []);
  const Success = async () => {
    var data = {
      reference_id: id,
    };
    const response = await ApiRequests.postValues(
      APIList.API_URL,
      APIList.API_LIST.CHECK_TOKEN,
      data
    );
    console.log(response);
    if (response.data) {
      setLoader(false);
      dispatch(
        updateState(ActionTypes.CUSTOMER_DETAILS, {
          customerList: response?.data?.payment_data,
        })
      );
      const customerDetails = response?.data?.payment_data;
      if (customerDetails?.payment_gateway === "ngenius") {
        document.documentElement.style.setProperty(
          "--main-theme-color",
          customerDetails?.theme_color
        );
        document.documentElement.style.setProperty(
          "--main-primary-color",
          customerDetails?.primary_color
        );
      }
    }
  };

  const transaction = async () => {
    const response = await ApiRequests.getValues(
      APIList.API_URL,
      APIList.API_LIST.GET_TRANS_DETAILS + "/" + customer?.customerList?.transaction_no,
      {}
    );
    console.log(response);
    if (response.data) {
      setTransaSuccess(response?.data?.sender);
    }
  };
  return (
    <div className="fls_payment_body">
      <div className="fls_payment_center">
        <PaymentDetails Loader={loading} />
        <div className="fls-card-right">
          {loading ? (
            <Loader />
          ) : (
            <>
              {transSuccess && (
                <div className="fls-customers-details">
                  <div className="fls-customer-name">
                    {transSuccess?.name || ""}
                  </div>
                  <div className="fls-customer-contact">
                    {transSuccess?.phone_code + transSuccess?.phone_number ||
                      ""}{" "}
                    | {transSuccess?.email || ""}
                  </div>
                </div>
              )}

              <div className="fls-card-cancel">
                <div className="fls-cancel">Payment Completed</div>
                <div className="fls-cancel-notes">
                  Payment ID: #{customer?.customerList?.transaction_no}
                </div>
                <img
                  className="fls-cancel-img"
                  src={imagePath("./success.jpg")}
                />
                <div className="fls-cancel-notes">
                  You have successfully paid{" "}
                  {customer?.customerList?.sender_currency}{" "}
                  {customer?.customerList?.payable_amount_in_sender_currency}
                </div>
                <div className="fls-done">Done</div>
              </div>
              <div className="fls-card-img">
                <img src={imagePath("./amex.png")} />
                <img src={imagePath("./master.png")} />
                <img src={imagePath("./visa.svg")} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Directsuccess;
