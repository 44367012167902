import { combineReducers } from "redux";
import { customer } from "../ReducerLayout/HomeLayout/Home";
import { country_details } from "../ReducerLayout/CountryLayout/CountryLayouts";




export default combineReducers({
// Customer Deatils
customer,
//Country Details
country_details,


})