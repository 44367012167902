import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import APIList from "../GlobalVariable/APIList";
import { PAGE_NAME } from "../Rootpath/rootPath";
import ApiRequests from "../ApiCall/ApiCall";
import PaymentDetails from "./PaymentDetails";
import Loader from "../Loader/loader";
import { ActionTypes } from "../constants/actionTypes";
const CryptoJS = window.CryptoJS;

const SamsungPay = () => {
  const { customer, country_details } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoader] = useState(true);
  const [tutelarpayload, setTutelarPayload] = useState("");

  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j).replace(/\s/g, "");
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };

  useEffect(() => {
    samsungcall();
    loadTutelarSdk();
  }, []);
  const encryptMethod = (text) => {
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      process.env.REACT_APP_KEY,
      { format: CryptoJSAesJson }
    ).toString();
    return encrypted;
  };

  const getQueryParams = (query) => {
    const params = new URLSearchParams(query);
    return Object.fromEntries(params.entries());
  };

  const queryParams = getQueryParams(location.search);
  var trans ={};
  const decodedOrderData = decodeURIComponent(queryParams.orderdata);
  trans = JSON.parse(decodedOrderData);


  const samsungcall = async () => {
    let orderData = {};
    if (queryParams.orderdata) {
      try {
        const decodedOrderData = decodeURIComponent(queryParams.orderdata);
        orderData = JSON.parse(decodedOrderData);
      } catch (error) {
        console.error("Error parsing orderdata:", error);
      }
    }
    document.documentElement.style.setProperty('--main-theme-color','#'+orderData.merchant.theme_color);
    document.documentElement.style.setProperty('--main-primary-color','#'+orderData.merchant.primary_color); 
    var data = {
      payment_reference: queryParams.ref_id,
    }; 
    const response = await ApiRequests.postValues(
      APIList.GET_SAMSUNG_TOKEN,
      "",
      data,
      {}
    );
    dispatch(updateState(ActionTypes.CUSTOMER_DETAILS,{
        customerList:orderData
    }))
    if (response.data) {
        var samsungdata = response.data.payment_response;
      setTimeout(() => {
        completePayment(samsungdata);
      }, 2000);
    } else {
      navigate(
        `${PAGE_NAME.CardCancel + "/" + trans?.transaction?.reference_id}`
      );
    }
  };

  const loadTutelarSdk = () => {
    if (window.Tutelar) {
        let tutelarRef = new window.Tutelar({
          public_key: "tutpk_live_fySLEqoxgW7F",
        });
        tutelarRef
          .then((resp) => {
            setTutelarPayload(resp.getClientDetails());
          })
          .catch((err) => {
            console.log(err);
            setTutelarPayload(err);
          });
       }
  };

  const completePayment = async (samsungdata) => {
    const payload = queryParams.payload ;
    let encrypt = {};
    if (queryParams.orderdata) {
      try {
        const decodedOrderData = decodeURIComponent(queryParams.orderdata);
        encrypt = JSON.parse(decodedOrderData);
      } catch (error) {
        console.error("Error parsing orderdata:", error);
      }
    }
    var orderData = {
      customer: encrypt?.customer,
      transaction: {
        reference_id: encrypt?.transaction?.reference_id,
        success_url:
          APIList.USER_URL +
          PAGE_NAME.Success +
          "?refid=" +
          customer?.customerList?.transaction_no,
        cancel_url:
          APIList.USER_URL +
          PAGE_NAME.Cancel +
          "?refid=" +
          customer?.customerList?.transaction_no,
      },
    };

    var data = {
      order: encryptMethod(orderData),
    };
    data.samsung_pay_token = samsungdata;
    if (payload == "tutelar") {
      data.tutelar_payload = tutelarpayload;
    }
    data.fraud_check = "enabled";
    const response = await ApiRequests.postValues(
      APIList.API_URL,
      APIList.API_LIST.CHARGE_REQUEST,
      data,
      {}
    );
    console.log(response);
    if (response.data !== undefined) {
      if (response.data.threed_secure == 0 && response.data.success == 1) {
        setLoader(false);
        navigate(
          `${
            PAGE_NAME.Directsuccess +
            "/" +
            encrypt?.transaction?.reference_id +
            "?transaction_no=" +
            response.data.transaction_id
          }`
        );
      } else {
        navigate(
          `${
            PAGE_NAME.Directcancel +
            "/" +
            encrypt?.transaction?.reference_id +
            "?transaction_no=" +
            response.data.transaction_id
          }`
        );
      }
    }else if(response.status !== 422 && response.data === undefined ){
          dispatch(
            updateState(ActionTypes.CUSTOMER_DETAILS, {
              showCard: "card_screen",
            })
          );
    } else{
      console.log(trans,"sssss");
      
      navigate(`${PAGE_NAME.CardCancel + "/" + trans?.transaction?.reference_id}`)
    }
  };
  return (
    <div className="fls_payment_body">
        <div className="fls_payment_center">
            <PaymentDetails />
            <div className="fls-card-right">
                {loading &&
                <Loader/>
            }
            </div>
        </div>
    </div>
);
};

export default SamsungPay;
