import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  link_mobile: "",
  postal_code: "",
  email: "",
  name: "",
  ph_number: "",
  state: "",
  Valid_state: false,
  countryFlag: false,
  current_country: {
  },
  headers: {
    access_for: "pay_link",
  },
  selectedCountry: {},
  click2pay_agree: true,
  click2pay_agree_card: false,
  customerList: {},
  getcountryList: {},
  showCard:"home_screen",
  cardname:"",
  cardnumber:"",
  cardExpiryMonth:"",
  cardExpiryYear:"",
  cvv:"",
  creditCardType:"",
  C2P_Enable:false,
  AddNewCard:false,
  backtoCard:false,
};

export function customer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CUSTOMER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
