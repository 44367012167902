import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ApiRequests from "../ApiCall/ApiCall";
import APIList from "../GlobalVariable/APIList";
import { ActionTypes } from "../constants/actionTypes";
import PaymentDetails from "./PaymentDetails";
import { imagePath } from "../DataServices/Image";
import Loader from "../Loader/loader";

const Directcancel = () => {
  const { customer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoader] = useState(true);
  const [transSuccess, setTransaSuccess] = useState(null)

  useEffect(() => {
    Success();
    transaction();
  }, []);
  const Success = async () => {
    var data = {
      reference_id: id,
    };
    const response = await ApiRequests.postValues(
      APIList.API_URL,
      APIList.API_LIST.CHECK_TOKEN,
      data
    );
    console.log(response);
    if (response.data) {
      setLoader(false);
      dispatch(
        updateState(ActionTypes.CUSTOMER_DETAILS, {
          customerList: response?.data?.payment_data,
        })
      );
      const customerDetails = response?.data?.payment_data;
      if (customerDetails?.payment_gateway === "ngenius") {
        document.documentElement.style.setProperty(
          "--main-theme-color",
          customerDetails?.theme_color
        );
        document.documentElement.style.setProperty(
          "--main-primary-color",
          customerDetails?.primary_color
        );
      }
    }
  };

    const retry = () =>{
      window.location.href=`${APIList.API_URL,APIList.USER_URL+'/pay/'+id}`
    }

    var params = new URLSearchParams(window.location.search);
const transaction = async()=>{
    const response = await ApiRequests.getValues(APIList.API_URL,APIList.API_LIST.GET_TRANS_DETAILS+'/'+customer?.customerList?.transaction_no,{})
    console.log(response);
    if (response.data) {
        setTransaSuccess(response?.data?.sender)
    }
    
}
  return(
    <div className="fls_payment_body">
    <div className="fls_payment_center">
      <PaymentDetails Loader={loading} />
        <div className="fls-card-right">
        {
        loading?
        <Loader/>
        :
          <>
         {transSuccess && 
             <div className="fls-customers-details">
             <div className="fls-customer-name">{transSuccess?.name}</div>
             <div className="fls-customer-contact">
               {transSuccess?.phone_code+transSuccess?.phone_number} | {transSuccess?.email}
             </div>
           </div>
         }
        <div className="fls-card-cancel">
          <div className="fls-cancel">Payment Cancelled</div>
          <div className="fls-cancel-notes">Payment ID: #{customer?.customerList?.transaction_no}</div>
          <img className="fls-cancel-img" src={imagePath("./cancel.jpg")} />
          <div className="fls-cancel-notes">
          Note : If the amount is deducted from your card. It will be refunded immediately. Please contact your bank for support.
          </div>
          <button className="fls-retry" onClick={retry}>Retry</button>
        </div>
        <div className="fls-card-img">
          <img src={imagePath("./amex.png")} />
          <img src={imagePath("./master.png")} />
          <img src={imagePath("./visa.svg")} />
        </div>
          </>
}
      </div>
    </div>
  </div>
  )
};

export default Directcancel;
