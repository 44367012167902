import React from "react";
import { imagePath } from "../DataServices/Image";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Success = () => {
  const { customer } = useSelector((state) => state);
  const location = useLocation();
  const getQueryParams = (query) => {
    const params = new URLSearchParams(query);
    return Object.fromEntries(params.entries());
  };
  console.log(location.search,"location.searchlocation.search");
  

  const queryParams = getQueryParams(location.search);

  return (
    <div className="fls-card-cancel">
      <div className="fls-cancel">Payment Completed</div>
      <div className="fls-cancel-notes">
        Payment ID: #{queryParams?.transaction_no}
      </div>
      <img className="fls-cancel-img" src={imagePath("./success.jpg")} />
      <div className="fls-cancel-notes">
        You have successfully paid {customer?.customerList?.sender_currency}{" "}
        {customer?.customerList?.payable_amount_in_sender_currency}
      </div>
      <div className="fls-done">Done</div>
    </div>
  );
};

export default Success;
