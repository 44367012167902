import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
    current_country: {
        as: "",
        city:  "Dubai",
        country: "",
        countryCode:  "AE",
        isp: "",
        lat: "",
        lon: "",
        org: "",
        query: "",
        region: "",
        regionName: "Dubai",
        status: "",
        timezone: "",
        zip:  "000000",
      },

};

export function country_details(state = initialState, action) {
    switch (action.type) {
  
        case ActionTypes.COUNTRY_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state
    }
}

