import React from "react";
import { imagePath } from "../DataServices/Image";
import { useLocation } from "react-router-dom";


const Cancel = () => {
    const location = useLocation();
      const retry = () =>{
        window.top.location.reload();
      }

      const getQueryParams = (query) => {
        const params = new URLSearchParams(query);
        return Object.fromEntries(params.entries());
      };
    
      const queryParams = getQueryParams(location.search);
    
    
  return (
    <div className="fls-card-cancel">
      <div className="fls-cancel">Payment Cancelled</div>
      <div className="fls-cancel-notes">Payment ID: #{queryParams?.transaction_no}</div>
      <img className="fls-cancel-img" src={imagePath("./cancel.jpg")} />
      <div className="fls-cancel-notes">Note : If the amount is deducted from your card. It will be refunded immediately. Please contact your bank for support.</div>
      <button className="fls-retry" onClick={retry}>
        Retry
      </button>
    </div>
  );
};

export default Cancel;
