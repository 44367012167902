import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PAGE_NAME } from "./Rootpath/rootPath";
import Loader from "./Loader/loader";
import SamsungPay from "./components/SamSungPay";
import CardCancel from "./components/CardCancel";
import Cancel from "./components/Cancel";
import Directsuccess from "./components/Directsuccess";
import Directcancel from "./components/DirectCancel";
import Success from "./components/Success";
import SamsungCancel from "./components/SamsungCancel";
const HomePage = React.lazy(()=> import('./components/Home'));

const App = () => {

  return (
    <>
        <Suspense>
          <Routes>
            <Route path={PAGE_NAME.Home + "/:id"} element={<HomePage />} />
            <Route path={PAGE_NAME.SamsungPay} element={<SamsungPay/>}/>
            <Route path={PAGE_NAME.SamsungCancel} element={<SamsungCancel/>}/>
            <Route  path={PAGE_NAME.Success} element={<Success/>} />
            <Route  path={PAGE_NAME.Cancel} element={<Cancel/>} />
            <Route  path={PAGE_NAME.Directsuccess+'/:id'} element={<Directsuccess/>} />
            <Route  path={PAGE_NAME.Directcancel+'/:id'} element={<Directcancel/>} /> 
            <Route  path={PAGE_NAME.CardCancel+'/:id'}  element={<CardCancel/>} /> 
          </Routes>
        </Suspense>
    </>
  );
};

export default App;
