export const PAGE_NAME ={
    Root : '/pay',
    Home : '/pay',
    Success : '/success',
    Cancel : '/cancel',
    Decline : '/decline',
    Directsuccess:'/directsuccess',
    Directcancel:'/directcancel',
    CardCancel:'/card-cancel',
    SamsungCancel:'/samsungpay-cancel',
    SamsungPay:'/samsung-pay'
}