import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/style/style.css";
import './assets/style/responsive.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/flag.css";
import { Provider } from "react-redux";
import { persistor, store } from "./store/configration";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorkers";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Disable console.log in production
if (process.env.NODE_ENV !== "development") console.log = () => {};
if (navigator.userAgent.indexOf("iPhone") > -1) {
  document
    .querySelector("[name=viewport]")
    .setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
}
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

serviceWorker.unregister();
