import React, { useState } from "react";

const Loader = () => {

  return(
    <>
    <div
      className="skeleton skeleton-text"
      style={{ width: "50%", height: "20px", marginBottom: "10px" }}
    ></div>
    <div className="fls-filling-field">
      <div
        className="skeleton skeleton-text"
        style={{
          width: "100%",
          height: "40px",
          marginBottom: "10px",
        }}
      ></div>
      <div
        className="skeleton skeleton-text"
        style={{
          width: "100%",
          height: "40px",
          marginBottom: "10px",
        }}
      ></div>
      <div
        className="skeleton skeleton-text"
        style={{
          width: "100%",
          height: "40px",
          marginBottom: "10px",
        }}
      ></div>
    </div>

    <div className="fls-btn-btn">
      <div
        className="skeleton skeleton-button"
        style={{ width: "60%", height: "40px", margin: "auto" }}
      ></div>
    </div>
    <div className="fls-card-img">
      <div
        className="skeleton skeleton-image"
        style={{
          width: "50px",
          height: "30px",
          marginRight: "10px",
        }}
      ></div>
      <div
        className="skeleton skeleton-image"
        style={{
          width: "50px",
          height: "30px",
          marginRight: "10px",
        }}
      ></div>
      <div
        className="skeleton skeleton-image"
        style={{ width: "50px", height: "30px" }}
      ></div>
    </div>
  </>
  )
};

export default Loader;
