import React, { useEffect, useState } from "react";
import { useLocation,} from "react-router-dom";
import PaymentDetails from "./PaymentDetails";
import { imagePath } from "../DataServices/Image";
import { PAGE_NAME } from "../Rootpath/rootPath";
import { useSelector,useDispatch } from "react-redux";
import { ActionTypes } from "../constants/actionTypes";

const SamsungCancel = () => {
  const {customer} = useSelector((state)=>state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const location = useLocation();

  const getQueryParams = (query) => {
    const params = new URLSearchParams(query);
    return Object.fromEntries(params.entries());
  };

  const queryParams = getQueryParams(location.search);

  let orderData={};
  useEffect(()=>{

    if (queryParams.orderdata) {
      try {
        const decodedOrderData = decodeURIComponent(queryParams.orderdata);
        orderData = JSON.parse(decodedOrderData);
        console.log(orderData,"orderData");
        
      } catch (error) {
        console.error("Error parsing orderdata:", error);
      }
    }
    document.documentElement.style.setProperty('--main-theme-color','#'+orderData.merchant.theme_color);
    document.documentElement.style.setProperty('--main-primary-color','#'+orderData.merchant.primary_color); 
    dispatch(updateState(ActionTypes.CUSTOMER_DETAILS,{
        customerList:orderData
    }))
  },[])

  const retry = () => {
    let encrypt = {};
    if (queryParams.orderdata) {
      try {
        const decodedOrderData = decodeURIComponent(queryParams.orderdata);
        encrypt = JSON.parse(decodedOrderData);
      } catch (error) {
        console.error("Error parsing orderdata:", error);
      }
    }
    window.location.href = `${
      PAGE_NAME.Home + "/" + encrypt?.transaction?.reference_id
    }`;
    console.log(encrypt,"encryptencryptencrypt");
    
  };
  
  return (
    <div className="fls_payment_body">
      <div className="fls_payment_center">
        <PaymentDetails />
        <div className="fls-card-right">
          <div className="fls-customers-details">
            <div className="fls-customer-name">Payment Details</div>
          </div>
          <div className="fls-card-cancel">
            <div className="fls-cancel">Payment Cancelled</div>
            <img className="fls-cancel-img" src={imagePath("./cancel.jpg")} />
            <div className="fls-cancel-notes">
              Note : Samsung Pay not supported
            </div>
            <button className="fls-retry" onClick={retry}>
              Retry
            </button>
          </div>
          <div className="fls-card-img">
            <img src={imagePath("./amex.png")} />
            <img src={imagePath("./master.png")} />
            <img src={imagePath("./visa.svg")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamsungCancel;
