import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from '../actions/combineReducer';
// import { promiseMiddleware } from './middleware';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const isProduction = process.env.NODE_ENV === 'production';

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false, // Disable serializableCheck if needed
    })
      .concat(routerMiddleware(persistConfig)),
    //   .concat(promiseMiddleware),
  devTools: !isProduction,
});

// Create persistor
export const persistor = persistStore(store);

// // **Clear Redux Store on Page Reload**
// window.addEventListener('beforeunload', () => {
//   persistor.purge(); // Clears persisted Redux state on refresh
// });

persistor.purge(); // Clears persisted Redux state on reload

export default store;
