import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { imagePath } from "../DataServices/Image";

const PaymentDetails = ({Loader}) => {
      const { customer} = useSelector((state) => state);

  return (
    <>
      { Loader === true ? (
        <div className="fls-card-left">
          <div className="fls-merchant-title">
            <div
              className="skeleton skeleton-circle"
              style={{ width: "50px", height: "50px" }}
            ></div>
            <div
              className="skeleton skeleton-text"
              style={{ width: "60%", height: "16px", marginLeft: "10px" }}
            ></div>
          </div>

          <div className="fls-merchnat-body">
            <div
              className="skeleton skeleton-text"
              style={{ width: "80%", height: "16px", marginBottom: "10px" }}
            ></div>
            <div
              className="skeleton skeleton-text"
              style={{ width: "50%", height: "16px", marginBottom: "10px" }}
            ></div>
            <div className="fls-merchant-details">
              <div>
                <div
                  className="skeleton skeleton-text"
                  style={{ width: "70%", height: "16px", marginBottom: "10px" }}
                ></div>
                <div
                  className="skeleton skeleton-text"
                  style={{ width: "60%", height: "16px", marginBottom: "10px" }}
                ></div>
              </div>
              <div>
                <div
                  className="skeleton skeleton-text"
                  style={{ width: "50%", height: "16px" }}
                ></div>
              </div>
            </div>
            <div
              className="skeleton skeleton-text"
              style={{ width: "90%", height: "16px", marginBottom: "10px" }}
            ></div>
            <div
              className="skeleton skeleton-text"
              style={{ width: "40%", height: "20px" }}
            ></div>
          </div>
          <div className="fls-merchnat-bottom">
            <div
              className="skeleton skeleton-rect"
              style={{ width: "100px", height: "50px" }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="fls-card-left">
          <div className="fls-merchant-title">
            <div className="fls-merchant-logo">
              <img className="fls-logo" src={customer?.customerList?.logo ?customer?.customerList?.logo:customer?.customerList?.merchant?.payment_screen_logo} alt="logo" />
            </div>
            <div className="fls-merchant-name">{customer?.customerList?.business_name ? customer?.customerList?.business_name : customer?.customerList?.merchant?.business_name}</div>
          </div>
          <div className="fls-merchnat-body">
            <div className="fls-sub-title">Payment For</div>
            <div className="fls-sub-details">{customer?.customerList?.description} {customer?.customerList?.receipt_no !== undefined && customer?.customerList?.receipt_no !== '' && '| Receipt Number - '+customer?.customerList?.receipt_no}</div>
            <div className="fls-merchant-details">
              <div>
                <div className="fls-sub-title">Payment ID </div>
                <div className="fls-sub-details">#{customer?.customerList?.reference_no?customer?.customerList?.reference_no:customer?.customerList?.transaction?.reference_id}</div>
              </div>
              <div>
                <div className="fls-sub-details">Date: {customer?.customerList?.created}</div>
              </div>
            </div>
            <div className="fls-sub-title">Total Payable Amount</div>
            <div className="fls-Pay-amount">{customer?.customerList?.sender_currency} {customer?.customerList?.payable_amount_in_sender_currency}</div>
          </div>
          <div className="fls-merchnat-bottom">
            <img src={imagePath('./pci.png')} alt="PCI" />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
