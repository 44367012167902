import axios from 'axios';
import APIList from "../GlobalVariable/APIList";

const ApiRequests = {
  returnCurrentCountry: async () => {
    try {
      const response = await axios.get(APIList.GET_COUNTRY);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return handleError(error);
    }
  },

  postValues: async (API_URL, URLTYPE, params, header) => {
    try {
      const response = await axios.post(API_URL + URLTYPE, params, { headers: header });      
      if (response.status === 200) {
        return response.data;
      }
      if (response.status === 422) {
        return { status: 422, message: response.data?.message || 'Unprocessable Entity' };
      }
    } catch (error) {
      return handleError(error);
    }
  },

  postCheckTokenValues: async (API_URL, URLTYPE, params, header) => {
    try {
      const response = await axios.post(API_URL + URLTYPE, params, { headers: header });
      if (response.status === 200) {
        return response.data;
      }
      if (response.status === 422) {
        return { status: 422, message: response.data?.message || 'Unprocessable Entity',error:response.data.error };
      }
    } catch (error) {
      return handleError(error);
    }
  },

  getValues: async (API_URL, URLTYPE, params, header) => {
    try {
      const response = await axios.get(API_URL + URLTYPE, { headers: header, data: params });
      if (response.status === 200) {
        return response.data;
      }
      if (response.status === 422) {
        return { status: 422, message: response.data?.message || 'Unprocessable Entity' };
      }
    } catch (error) {
      return handleError(error);
    }
  },
};

const handleError = (error) => {
  if (error.response) {
    return {
      status: error.response.status,
      message: error.response.data?.message || 'An error occurred',
      error: error.response.data.error
    };
  } else if (error.request) {
    return {
      status: 500,
      message: 'No response from server. Please try again later.',
    };
  } else {
    return {
      status: 500,
      message: error.message || 'Unexpected error occurred',
    };
  }
};

export default ApiRequests;
