
module.exports = {
  // USER_URL: "https://link.foloosi.com",
  // USER_URL:"http://localhost:3000",
  // USER_URL:"https://sandbox-payment.foloosi.com",
  USER_URL:"https://order.foloosi.com",
  API_URL: "https://api.foloosi.com/aggregator/",
  SAMSUNG_PAY: "https://api.foloosi.com/aggregator/web/spay/order/create",
  GET_SAMSUNG_TOKEN: "https://api.foloosi.com/aggregator/web/spay/payment/data",
  GET_COUNTRY: "https://pro.ip-api.com/json?key=RMV9nkZYs1rNPZQ",
  Neutrinoapi: "https://neutrinoapi.net/",
  NeutrinoKey: "HBYAkwD7MvWPGvs9w4kle2BXrOnOwLBuhRGQ14NfJylEE5rF",
  API_LIST: {
    CHECK_TOKEN: "paylink/check-token/web",
    CHARGE_REQUEST: "paylink/payment-request",
    GET_TRANS_DETAILS: "pay-link/trans-info",
  },
};
